<template>
  <MainLayout
    isFullScreen
    class="main-layout--password-reset">
    <form
      @submit.prevent="handlePasswordResetSubmit"
      ref="form"
      class="password-reset__form-wrapper"
      novalidate>
      <header class="password-reset__form-header">
        <Heading
          isTextCentered
          isCapitalized>
          {{ $t('artist.passwordReset.newPassword') }}
        </Heading>
        <Paragraph isTextCentered>
          {{ $t('artist.passwordReset.confirmNewPassword') }}
        </Paragraph>
      </header>

      <FieldInput
        v-model="newPassword"
        :label="$t('common.password')"
        :hasRightIcons="Boolean(newPassword)"
        isAutofocus
        id="password-reset-password-input"
        type="password"
        autocomplete="new-password"
        minlength="8"
        required
        class="mb-sm"
        ref="password">
        <template
          v-if="newPassword"
          #icons-right>
          <Icon
            @click="$refs.password.$refs.input.type = $refs.password.$refs.input.type === 'password' ? 'text' : 'password'"
            :variant="'view-outline'"
            :tag="'button'"
            :size="'md'"
            class="ml-xs"
            type="button">
          </Icon>
        </template>
      </FieldInput>
      <FieldInput
        v-model="confirmationPassword"
        :errors="errors"
        :label="$t('common.confirmPassword')"
        id="password-reset-password-confirmation-input"
        autocomplete="new-password"
        type="password"
        required
        class="mb-lg">
      </FieldInput>

      <Loader v-if="isLoading"></Loader>
      <Button
        v-else
        type="submit"
        class="mt-auto mr-auto">
        {{ $t('common.confirm') }}
      </Button>
    </form>
  </MainLayout>
</template>

<script>

import { mapMutations }                 from 'vuex';

import MainLayout                       from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Loader                           from '../../../components/atoms/Loader/a-Loader.vue';
import Heading                          from '../../../components/atoms/Heading/a-Heading.vue';
import Icon                             from '../../../components/atoms/Icon/a-Icon.vue';
import Paragraph                        from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import FieldInput                       from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button                           from '../../../components/atoms/Button/a-Button.vue';
import { PASSWORD_RESET }               from '../../../graphql/shared/queries/q-PasswordReset.js';
import { PASSWORD_RESET_RESPONSE }      from '../../../graphql/shared/responses/r-PasswordReset.js';


export default {
  name: 'p-PasswordReset',
  components: {
    MainLayout,
    Loader,
    Heading,
    Icon,
    Paragraph,
    FieldInput,
    Button
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    newPassword: '',
    confirmationPassword: '',
    errors: []
  }),
  computed: {
    isConfirmationPasswordMatching() {
      return this.newPassword === this.confirmationPassword;
    }
  },
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
      setUser: 'User/SET_USER'
    }),
    async handlePasswordResetSubmit() {
      const isValidForm = this.$refs.form.checkValidity();

      if (!isValidForm) return;
      if (!this.isConfirmationPasswordMatching) {
        return this.errors.push({ type: 'alert', message: this.$t('common.errors.passwordsMustMatch') });
      }

      try {
        this.isLoading = true;
        this.setUser({ Token: this.token });

        const input = { password: this.newPassword };
        const data = await this.$apollo.mutate({
          mutation: PASSWORD_RESET,
          variables: input
        });
        const { __typename: responseTypeName } = data.data.resetPassword;

        switch (responseTypeName) {
          case PASSWORD_RESET_RESPONSE.artist:
            this.$router.push('/artist');
            break;

          case PASSWORD_RESET_RESPONSE.client:
            this.$router.push('/client');
            break;

          case PASSWORD_RESET_RESPONSE.wrongInput:
            this.addErrors([{ type: 'alert', message: this.$t(`artist.passwordReset.errors.${responseTypeName}`) }]);
            break;
        }
      } catch (error) {
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
      } finally {
        this.isLoading = false;
      }
    }
  },
  watch: {
    confirmationPassword() {
      if (this.isConfirmationPasswordMatching) this.errors = [];
    }
  }
}

</script>

<style lang="scss">

.password-reset {
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 450px;
    max-width: 450px;
  }

  &__form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: var(--space-lg);
  }
}

</style>